import { Modal } from "bootstrap";

import ApplicationController from "./application_controller";

/* This is the custom StimulusReflex controller for the Admin Reflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {
  /*
   * Regular Stimulus lifecycle methods
   * Learn more at: https://stimulusjs.org/reference/lifecycle-callbacks
   *
   * If you intend to use this controller as a regular stimulus controller as well,
   * make sure any Stimulus lifecycle methods overridden in ApplicationController call super.
   *
   * Important:
   * By default, StimulusReflex overrides the -connect- method so make sure you
   * call super if you intend to do anything else when this controller connects.
   */
  static values = { id: Number, title: String };

  // TODO: move this to a global config file for establishment
  emailDomain = "@edu.vs.ch";

  connect() {
    super.connect();
    // add your code here, if applicable
    console.log(location.hostname);
  }

  /* Reflex specific lifecycle methods.
   *
   * For every method defined in your Reflex class, a matching set of lifecycle methods become available
   * in this javascript controller. These are optional, so feel free to delete these stubs if you don't
   * need them.
   *
   * Important:
   * Make sure to add data-controller="admin" to your markup alongside
   * data-reflex="Admin#dance" for the lifecycle methods to fire properly.
   *
   * Example:
   *
   *   <a href="#" data-reflex="click->Admin#dance" data-controller="admin">Dance!</a>
   *
   * Arguments:
   *
   *   element - the element that triggered the reflex
   *             may be different than the Stimulus controller's this.element
   *
   *   reflex - the name of the reflex e.g. "Admin#dance"
   *
   *   error/noop - the error message (for reflexError), otherwise null
   *
   *   reflexId - a UUID4 or developer-provided unique identifier for each Reflex
   */
  beforeShow(element, reflex, noop, reflexId) {
    this.openDrawer(element.dataset);
    this.listenCloseDrawerClick();
  }

  beforeNew(element, reflex, noop, reflexId) {
    this.openDrawer();
    this.listenCloseDrawerClick();
  }

  beforeEditTimetable(element, reflex, noop, reflexId) {
    this.openDrawer();
    this.listenCloseDrawerClick();
  }

  afterNew(element, reflex, noop, reflexId) {
    this.formatForm();
  }

  afterEdit(element, reflex, noop, reflexId) {
    this.formatForm();
  }

  afterUpdate(element, reflex, noop, reflexId) {
    element.reset();
  }

  afterCreate(element, reflex, noop, reflexId) {
    element.reset();
  }

  beforeEdit(element, reflex, noop, reflexId) {
    this.openDrawer(element.dataset);
    this.listenCloseDrawerClick();
  }

  beforeEditDays(element, reflex, noop, reflexId) {
    this.openDrawer(element.dataset);
    this.listenCloseDrawerClick();
  }

  beforeEditPlanification(element, reflex, noop, reflexId) {
    this.openDrawer(element.dataset);
    this.listenCloseDrawerClick();
  }

  beforeEditComment(element, reflex, noop, reflexId) {
    console.log("beforeEditComment");
    this.openDrawer(element.dataset);
    this.listenCloseDrawerClick();
  }

  beforeBulkEmails(element, reflex, noop, reflexId) {
    console.log("bulk_emails");
    console.log(element.dataset);
    console.log(reflex);
    // stop execution of script
  }

  formatForm() {
    console.log("formatForm");
  }

  deleteEtab(event) {
    event.preventDefault();
    this.delete("Admin::Establishment#delete", this.idValue, this.titleValue);
  }

  deletePrestation(event) {
    event.preventDefault();
    this.delete("Admin::Prestation#delete", this.idValue, this.titleValue);
  }

  deleteLinkedPrestation(event) {
    event.preventDefault();
    this.delete(
      "Admin::LinkedPrestation#delete",
      this.idValue,
      this.titleValue,
    );
  }

  deleteLinkedUser(event) {
    event.preventDefault();
    this.delete("Admin::LinkedUser#delete", this.idValue, this.titleValue);
  }

  deleteCourse(event) {
    event.preventDefault();
    this.delete("Admin::Course#delete", this.idValue, this.titleValue);
  }

  deleteBuilding(event) {
    event.preventDefault();
    this.delete("Admin::Building#delete", this.idValue, this.titleValue);
  }

  deletePerson(event) {
    event.preventDefault();
    this.delete("Admin::Person#delete", this.idValue, this.titleValue);
  }

  deleteUser(event) {
    event.preventDefault();
    this.delete("Admin::User#delete", this.idValue, this.titleValue);
  }

  deleteInstructor(event) {
    event.preventDefault();
    this.delete("Admin::Instructor#delete", this.idValue, this.titleValue);
  }

  deleteAdministrator(event) {
    event.preventDefault();
    this.delete("Admin::Administrator#delete", this.idValue, this.titleValue);
  }

  deleteAbsence(event) {
    event.preventDefault();
    this.delete("Admin::Absence#delete", this.idValue, this.titleValue);
  }

  deleteHoliday(event) {
    event.preventDefault();
    this.delete("Admin::Holiday#delete", this.idValue, this.titleValue);
  }

  deleteExtraDay(event) {
    event.preventDefault();
    this.delete("Admin::ExtraDay#delete", this.idValue, this.titleValue);
  }

  deleteExpense(event) {
    event.preventDefault();
    this.delete("Admin::Validation#delete", this.idValue, this.titleValue);
  }

  deleteEss(event) {
    event.preventDefault();
    this.delete("Admin::Ess#delete", this.idValue, this.titleValue);
  }

  deleteScholarYear(event) {
    event.preventDefault();
    this.delete("Admin::ScholarYear#delete", this.idValue, this.titleValue);
  }

  deleteInvoice(event) {
    event.preventDefault();
    this.delete("Admin::Invoice#delete", this.idValue, this.titleValue);
  }

  deleteWorkDay(event) {
    event.preventDefault();
    this.delete("Admin::WorkDay#delete", this.idValue, this.titleValue);
  }

  checkAllInstructors(event) {
    console.log("checkAllInstructors");
    const checkboxes = document.querySelectorAll(
      'input[name="absence[instructor_ids][]"]',
    );
    if (event.target.checked) {
      checkboxes.forEach((checkbox) => {
        checkbox.checked = true;
      });
    } else {
      checkboxes.forEach((checkbox) => {
        checkbox.checked = false;
      });
    }
  }

  checkAllExpenses(event) {
    const checkboxes = document.querySelectorAll('input[name="expense_ids[]"]');
    if (event.target.checked) {
      checkboxes.forEach((checkbox) => {
        checkbox.checked = true;
      });
    } else {
      checkboxes.forEach((checkbox) => {
        checkbox.checked = false;
      });
    }
  }

  checkAllIgnoreControls(event) {
    console.log("check all ignore controls");
    const checkboxes = document.querySelectorAll(
      'input[name="ignore_control_ids[]"]',
    );
    if (event.target.checked) {
      checkboxes.forEach((checkbox) => {
        checkbox.checked = true;
      });
    } else {
      checkboxes.forEach((checkbox) => {
        checkbox.checked = false;
      });
    }
  }

  checkAllEmailActivities(event) {
    console.log("check all email activites");
    const checkboxes = document.querySelectorAll(
      'input[name="email_activity_ids[]"]',
    );
    if (event.target.checked) {
      checkboxes.forEach((checkbox) => {
        checkbox.checked = true;
      });
    } else {
      checkboxes.forEach((checkbox) => {
        checkbox.checked = false;
      });
    }
  }

  checkAllClosingActivities(event) {
    console.log("check all closing activites");
    const checkboxes = document.querySelectorAll(
      'input[name="closing_activity_ids[]"]',
    );
    if (event.target.checked) {
      checkboxes.forEach((checkbox) => {
        checkbox.checked = true;
      });
    } else {
      checkboxes.forEach((checkbox) => {
        checkbox.checked = false;
      });
    }
  }

  checkAllCourses(event) {
    console.log("check all courses");
    const checkboxes = document.querySelectorAll('input[name="course_ids[]"]');
    if (event.target.checked) {
      checkboxes.forEach((checkbox) => {
        checkbox.checked = true;
      });
    } else {
      checkboxes.forEach((checkbox) => {
        checkbox.checked = false;
      });
    }
  }

  bulk_open(event) {
    event.preventDefault();
    console.log("bulk_open");
  }
  plannify(event) {
    // stop propagation to avoid reflex
    event.preventDefault();
    let planning_region_id =
      document.getElementById("planning_region_id").value;
    let planning_region =
      document.getElementById("planning_region_id").selectedOptions[0].text;

    let date_range = document.getElementById("date_range").value;
    let date = document.getElementById("date_range").selectedOptions[0].text;
    let year = document.getElementById("year").value;
    let modalWrap = document.createElement("div");
    modalWrap.innerHTML = `
      <div id="modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
          aria-hidden="true" >
          <div class="modal-dialog" role="document">
              <div class="modal-content">
                  <div class="modal-header">
                      <h5 class="modal-title" id="exampleModalLabel">Êtes-vous bien sûr?</h5>
                      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                      </button>
                  </div>
                  <div class="modal-body">
                      <p class="mb-0">Voulez-vous lancer la plannification pour la période ${date} ${year} de la région ${planning_region} ?</p>
                  </div>
                  <div class="modal-footer">
                      <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Annuler</button>
                      <button type="button" class="btn btn-primary modal-success-btn" data-bs-dismiss="modal">Plannifier</button>
                  </div>
              </div>
          </div>
      </div>
      `;
    let callback = function () {
      console.log("plannify");
      // load current page with params select = 'Plannifier'
      let url = new URL(window.location.href);
      url.searchParams.set("select", "Plannifier");
      // window.location.href = url;
      console.log(year, date, planning_region);
      // window.location.href = `/admin/events?year=${year}&date_range=${date_range}&planning[region_id]=${planning_region_id}&select=Plannifier`;
      // Submit form with id 'form-select-planify' and params select = 'Plannifier'
      let form = document.getElementById("form-select-planify");
      // Find input with name 'select' and set value to 'Plannifier'
      let input = form.querySelector('input[name="planifier"]');
      input.value = "true";
      // Submit form
      form.submit();
    };
    modalWrap.querySelector(".modal-success-btn").onclick = callback;
    document.body.append(modalWrap);

    let modal = new Modal(modalWrap.querySelector("#modal"));
    modal.show();
  }

  close_establishment(event) {
    // stop propagation to avoid reflex
    event.preventDefault();
    let modalWrap = document.createElement("div");
    modalWrap.innerHTML = `
      <div id="modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
          aria-hidden="true" >
          <div class="modal-dialog" role="document">
              <div class="modal-content">
                  <div class="modal-header">
                      <h5 class="modal-title" id="exampleModalLabel">Êtes-vous bien sûr?</h5>
                      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                      </button>
                  </div>
                  <div class="modal-body">
                      <p class="mb-0">Voulez-vous clôturer la planification pour cet établissement ?</p>
                  </div>
                  <div class="modal-footer">
                      <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Annuler</button>
                      <button type="button" class="btn btn-primary modal-success-btn" data-bs-dismiss="modal">Clôturer</button>
                  </div>
              </div>
          </div>
      </div>
      `;

    let admin = this;
    let id = this.idValue;
    console.log("id", id);
    let callback = function () {
      console.log("cloturer", id);
      admin.stimulate("Admin::Establishment#close", id);
    };
    modalWrap.querySelector(".modal-success-btn").onclick = callback;
    document.body.append(modalWrap);

    let modal = new Modal(modalWrap.querySelector("#modal"));
    modal.show();
  }

  open_establishment(event) {
    // stop propagation to avoid reflex
    event.preventDefault();
    let modalWrap = document.createElement("div");
    modalWrap.innerHTML = `
      <div id="modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
          aria-hidden="true" >
          <div class="modal-dialog" role="document">
              <div class="modal-content">
                  <div class="modal-header">
                      <h5 class="modal-title" id="exampleModalLabel">Êtes-vous bien sûr?</h5>
                      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                      </button>
                  </div>
                  <div class="modal-body">
                      <p class="mb-0">Voulez-vous ouvrir la planification pour cet établissement ?</p>
                  </div>
                  <div class="modal-footer">
                      <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Annuler</button>
                      <button type="button" class="btn btn-primary modal-success-btn" data-bs-dismiss="modal">Ouvrir</button>
                  </div>
              </div>
          </div>
      </div>
      `;

    let admin = this;
    let id = this.idValue;
    console.log("id", id);
    let callback = function () {
      console.log("ouvrir", id);
      admin.stimulate("Admin::Establishment#open", id);
    };
    modalWrap.querySelector(".modal-success-btn").onclick = callback;
    document.body.append(modalWrap);

    let modal = new Modal(modalWrap.querySelector("#modal"));
    modal.show();
  }

  user_fill_email(event) {
    console.log("fill_email");
    console.log(location.hostname);
    event.preventDefault();
    // find the email input from event target
    const email = event.target
      .closest("form")
      .querySelector("input[name='user[email]']");
    // Find input with name="person[firstname]"
    const firstname =
      event.target
        .closest("form")
        .querySelector("input[name='user[first_name]']") || "";
    const lastname =
      event.target
        .closest("form")
        .querySelector("input[name='user[last_name]']") || "";
    // convert firstname and lastname for email without accents and ï and ë and ...
    const emailValue = `${firstname.value
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")}.${lastname.value
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")}${this.emailDomain}`;
    // fill the email input with the email value
    email.value = emailValue;
  }

  duplicate(event) {
    event.preventDefault();

    let id = this.idValue;
    let title = this.titleValue;
    let modalWrap = document.createElement("div");
    // add class to modalWrap

    modalWrap.innerHTML = `
      <div id="modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
          aria-hidden="true" >
          <div class="modal-dialog" role="document">
              <div class="modal-content">
                  <div class="modal-header">
                      <h5 class="modal-title" id="exampleModalLabel">Êtes-vous bien sûr?</h5>
                      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                      </button>
                  </div>
                  <div class="modal-body">
                      <p class="mb-0">Voulez-vous dupliquer le cours ${title}?</p>
                  </div>
                  <div class="modal-footer">
                      <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Annuler</button>
                      <button type="button" class="btn btn-primary modal-success-btn" data-bs-dismiss="modal">Dupliquer</button>
                  </div>
              </div>
          </div>
      </div>
      `;
    const dashboard = this;
    let callback = function () {
      console.log("duplicate", id);
      dashboard.stimulate("Admin::Course#duplicate", id);
    };
    modalWrap.querySelector(".modal-success-btn").onclick = callback;
    document.body.append(modalWrap);

    let modal = new Modal(modalWrap.querySelector("#modal"));
    modal.show();
  }

  setupScholarYear(event) {
    event.preventDefault();

    let id = this.idValue;
    let modalWrap = document.createElement("div");
    // add class to modalWrap

    modalWrap.innerHTML = `
      <div id="modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
          aria-hidden="true" >
          <div class="modal-dialog" role="document">
              <div class="modal-content">
                  <div class="modal-header">
                      <h5 class="modal-title" id="exampleModalLabel">Êtes-vous bien sûr?</h5>
                      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                      </button>
                  </div>
                  <div class="modal-body">
                      <p class="mb-0">Voulez-vous dupliquer les prestations de l'année précédente?</p>
                  </div>
                  <div class="modal-footer">
                      <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Annuler</button>
                      <button type="button" class="btn btn-primary modal-success-btn" data-bs-dismiss="modal">Dupliquer</button>
                  </div>
              </div>
          </div>
      </div>
      `;
    const dashboard = this;
    let callback = function () {
      dashboard.stimulate("Admin::ScholarYear#setup_scholar_year", id);
    };
    modalWrap.querySelector(".modal-success-btn").onclick = callback;
    document.body.append(modalWrap);

    let modal = new Modal(modalWrap.querySelector("#modal"));
    modal.show();
  }

  sendEmail(event) {
    event.preventDefault();

    let id = this.idValue;
    let title = this.titleValue;
    let method = event.target.dataset.method;
    console.log("send_email", id, method);
    let modalWrap = document.createElement("div");
    // add class to modalWrap

    modalWrap.innerHTML = `
      <div id="modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
          aria-hidden="true" >
          <div class="modal-dialog" role="document">
              <div class="modal-content">
                  <div class="modal-header">
                      <h5 class="modal-title" id="exampleModalLabel">Êtes-vous bien sûr?</h5>
                      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                      </button>
                  </div>
                  <div class="modal-body">
                      <p class="mb-0">Voulez-vous envoyer l'email ${title} ?</p>
                  </div>
                  <div class="modal-footer">
                      <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Annuler</button>
                      <button type="button" class="btn btn-primary modal-success-btn" data-bs-dismiss="modal">Envoyer</button>
                  </div>
              </div>
          </div>
      </div>
      `;
    const dashboard = this;
    let callback = function () {
      dashboard.stimulate("Admin::Establishment#send_email", id, method);
    };
    modalWrap.querySelector(".modal-success-btn").onclick = callback;
    document.body.append(modalWrap);

    let modal = new Modal(modalWrap.querySelector("#modal"));
    modal.show();
  }

  sendGroupedEmail(event) {
    event.preventDefault();

    let id = this.idValue;
    let title = this.titleValue;
    let method = event.target.dataset.method;
    console.log("send_email", id, method);
    let modalWrap = document.createElement("div");
    // add class to modalWrap

    modalWrap.innerHTML = `
      <div id="modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
          aria-hidden="true" >
          <div class="modal-dialog" role="document">
              <div class="modal-content">
                  <div class="modal-header">
                      <h5 class="modal-title" id="exampleModalLabel">Êtes-vous bien sûr?</h5>
                      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                      </button>
                  </div>
                  <div class="modal-body">
                      <p class="mb-0">Voulez-vous envoyer l'email à <red>${title} établissement</red> ?</p>
                  </div>
                  <div class="modal-footer">
                      <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Annuler</button>
                      <button type="button" class="btn btn-primary modal-success-btn" data-bs-dismiss="modal">Envoyer</button>
                  </div>
              </div>
          </div>
      </div>
      `;
    const dashboard = this;
    let callback = function () {
      dashboard.stimulate(
        "Admin::Establishment#send_grouped_email",
        id,
        method,
      );
    };
    modalWrap.querySelector(".modal-success-btn").onclick = callback;
    document.body.append(modalWrap);

    let modal = new Modal(modalWrap.querySelector("#modal"));
    modal.show();
  }
}
